import React from "react";
import { useStateIfMounted } from "use-state-if-mounted";

const CalendarContext = React.createContext();

export const CalendarProvider = ({ children }) => {

  //!Evento personal
  const [nombreEventoAgendarPersonal, setNombreEventoAgendarPersonal] = useStateIfMounted("");
  const [lugarEventoAgendarPersonal, setLugarEventoAgendarPersonal] = useStateIfMounted("");
  const [fechaEventoAgendarPersonal, setFechaEventoAgendarPersonal] = useStateIfMounted(new Date());
  const [horaInicioEventoAgendarPersonal, setHoraInicioEventoAgendarPersonal] = useStateIfMounted(null);
  const [horaFinEventoAgendarPersonal, setHoraFinEventoAgendarPersonal] = useStateIfMounted(null);
  const [mensajeEventoAgendarPersonal, setMensajeEventoAgendarPersonal] = useStateIfMounted("");
  //!Vacaciones
  const [nombreVacaciones, setNombreVacaciones] = useStateIfMounted("Vacaciones");
  const [lugarVacaciones, setLugarVacaciones] = useStateIfMounted("");
  const [fechaIniVacaciones, setFechaIniVacaciones] = useStateIfMounted(new Date());
  const [fechaFinVacaciones, setFechaFinVacaciones] = useStateIfMounted(new Date());
  const [horaInicioVacaciones, setHoraInicioVacaciones] = useStateIfMounted(null);
  const [horaFinVacaciones, setHoraFinVacaciones] = useStateIfMounted(null);
  const [mensajeVacaciones, setMensajeVacaciones] = useStateIfMounted("");


  return (
    <CalendarContext.Provider
      value={{
        nombreEventoAgendarPersonal,
        setNombreEventoAgendarPersonal,
        lugarEventoAgendarPersonal,
        setLugarEventoAgendarPersonal,
        fechaEventoAgendarPersonal,
        setFechaEventoAgendarPersonal,
        horaInicioEventoAgendarPersonal,
        setHoraInicioEventoAgendarPersonal,
        horaFinEventoAgendarPersonal,
        setHoraFinEventoAgendarPersonal,
        mensajeEventoAgendarPersonal,
        setMensajeEventoAgendarPersonal,
        nombreVacaciones,
        setNombreVacaciones,
        lugarVacaciones,
        setLugarVacaciones,
        fechaIniVacaciones,
        setFechaIniVacaciones,
        fechaFinVacaciones,
        setFechaFinVacaciones,
        horaInicioVacaciones,
        setHoraInicioVacaciones,
        horaFinVacaciones,
        setHoraFinVacaciones,
        mensajeVacaciones,
        setMensajeVacaciones,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarContext;
