export default {
  mainLighter: "#F7F9F9",
  mainLight: "#F8F9F9",
  main: "#F2F3F4",

  secondaryLighter: "#F2F2F2",
  secondaryLight: "#CCCECF",
  secondary: "#9C9FA3",
  secondaryDark: "#6F7378",
  secondaryDarken: "#454A50",
  techDarkBlue: "#1F252B",

  textWhite: "#FFFFFF",
  textSecondaryLighter: "#F2F2F2",
  textSecondaryLight: "#CCCECF",
  textSecondary: "#9C9FA3",
  textSecondaryDark: "#6F7378",
  textSecondaryDarken: "#454A50",
  text: "#1F252B",

  accentLighter: "#CCD8FF",
  accentLight: "#B3C5FF",
  accent: "#3162FF",
  accentSecondary: "#668AFF",
  branding: "#3162FF",

  purpleLighter: "#D7D3EA",
  purpleLight: "#C4BBEA",
  purple: "#7259EA",

  successLighter: "#D4EFDF",
  successLight: "#7DCEA0",
  success: "#27AE60",

  orangeLighter: "#FDEBD0",
  orangeLight: "#F8C471",
  orange: "#FF9000",

  errorLighter: "#FADBD8",
  errorLight: "#F1948A",
  error: "#FB2D37",

  yellowLighter: "#FFF5CC",
  yellowLight: "#FFE266",
  yellow: "#FFDA3E",
};
