import React from "react";
import colors from "../../constants/colors";
import color from "../../constants/colors";

export const LargeTitle = (props) => {
  return (
    <div
      style={{
        marginBottom: props.marginBottom ? props.marginBottom : 10,
        marginTop: props.marginTop ? props.marginTop : 0,
      }}
      className="truncate"
    >
      <span
        style={{
          fontSize: 42,
          fontWeight: props.bold ? 600 : "regular",
          color: props.color ? props.color : color.text,
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

export const Title1 = (props) => {
  return (
    <div
      style={{
        marginBottom: props.marginBottom ? props.marginBottom : 0,
        marginTop: props.marginTop ? props.marginTop : 0,
      }}
      className="truncate"
    >
      <span
        style={{
          fontSize: 30,
          fontWeight: props.bold ? 600 : "regular",
          color: props.color ? props.color : color.textSecondaryDarken,
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

export const Title2 = (props) => {
  return (
    <div
      style={{
        marginBottom: props.marginBottom ? props.marginBottom : 0,
        marginTop: props.marginTop ? props.marginTop : 0,
      }}
      className="truncate"
    >
      <span
        style={{
          fontSize: 26,
          fontWeight: props.bold ? 600 : "regular",
          color: props.color ? props.color : color.textSecondaryDarken,
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

export const Title3 = (props) => {
  return (
    <div
      style={{
        marginBottom: props.marginBottom ? props.marginBottom : 0,
        marginTop: props.marginTop ? props.marginTop : 0,
      }}
      className="truncate"
    >
      <span
        style={{
          fontSize: 22,
          fontWeight: props.bold ? 600 : "regular",
          color: props.color ? props.color : color.text,
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

export const HeadLine = (props) => {
  return (
    <div
      style={{
        marginBottom: props.marginBottom ? props.marginBottom : 0,
        marginTop: props.marginTop ? props.marginTop : 0,
      }}
      className="truncate"
    >
      <span
        style={{
          fontSize: 21,
          fontWeight: props.bold ? 600 : "regular",
          color: props.color ? props.color : color.text,
          lineHeight: 0.75,
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

export const SubHeadLine = (props) => {
  return (
    <div
      style={{
        marginBottom: props.marginBottom ? props.marginBottom : 0,
        marginTop: props.marginTop ? props.marginTop : 0,
      }}
      className={`${props.className} " truncate"`}
    >
      <span style={{
        fontSize: props.personalFont ? props.personalFont : 18, fontWeight: props.bold ? "500" : "regular",
        color: props.color ? props.color : color.secondaryDarken,
      }}
      >
        {props.text}
      </span>
    </div>
  );
};

export const SubSubHeadLine = (props) => {
  return (
    <div
      style={{
        marginBottom: props.marginBottom ? props.marginBottom : 0,
        marginTop: props.marginTop ? props.marginTop : 0,
      }}
      className="truncate"
    >
      <span
        style={{
          fontSize: 16,
          fontWeight: props.bold ? 600 : "regular",
          color: props.color ? props.color : colors.textSecondaryDarken,
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

export const BodyText = (props) => {
  return (
    <div
      style={{
        marginBottom: props.marginBottom ? props.marginBottom : 0,
        marginTop: props.marginTop ? props.marginTop : 0,
      }}
      className="truncate"
    >
      <span
        style={{
          fontSize: 21,
          fontWeight: props.bold ? 600 : "regular",
          color: props.color ? props.color : color.text,
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

export const CallOutText = (props) => {
  return (
    <div
      style={{
        marginBottom: props.marginBottom ? props.marginBottom : 0,
        marginTop: props.marginTop ? props.marginTop : 0,
      }}
      className="truncate"
    >
      <span
        style={{
          fontSize: 20,
          fontWeight: props.bold ? 600 : "regular",
          color: props.color ? props.color : color.text,
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

export const FootNoteText = (props) => {
  return (
    <div
      style={{
        marginBottom: props.marginBottom ? props.marginBottom : 0,
        marginTop: props.marginTop ? props.marginTop : 0,
      }}
      className="truncate"
    >
      <span
        style={{
          fontSize: props.personalFont ? props.personalFont : 17,
          fontWeight: props.bold ? 600 : "regular",
          color: props.color ? props.color : color.text,
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

export const CaptionText = (props) => {
  return (
    <div
      style={{
        ...props.style,
        marginBottom: props.marginBottom ? props.marginBottom : 0,
        marginTop: props.marginTop ? props.marginTop : 0,
        color: props.color ? props.color : colors.textSecondaryDarken,
      }}
      className={props.noTruncate ? "" : "truncate"}
    >
      <span style={{
        fontSize: props.personalFont ? props.personalFont : 14,
        fontWeight: props.bold ? 600 : "regular",
        color: props.color ? props.color : color.textSecondaryDarken,
      }}
      >
        {props.text}
      </span>
    </div>
  );
};

export const TagText = (props) => {
  return (
    <div
      style={{
        marginBottom: props.marginBottom ? props.marginBottom : 0,
        marginTop: props.marginTop ? props.marginTop : 0,
      }}
      className="truncate"
    >
      <span
        style={{
          fontSize: 12,
          fontWeight: props.bold ? 600 : "regular",
          color: props.color ? props.color : color.text,
        }}
      >
        {props.text}
      </span>
    </div>
  );
};
