import React from "react";
import { Modal } from "rsuite";
import { useStateIfMounted } from "use-state-if-mounted";
import EspecialistasAPIClass from "../services/especialistas";
import PacientesAPIClass from "../services/pacientes";

const UserContext = React.createContext();
const PacientesAPI = new PacientesAPIClass();
const EspecialistasAPI = new EspecialistasAPIClass();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useStateIfMounted(false);
  const [organizacion, setOrganizacion] = useStateIfMounted(false);
  const [subCuentas, setSubCuentas] = useStateIfMounted(false);
  const [tokens, setTokens] = useStateIfMounted([]);
  const [lat, setLat] = useStateIfMounted("");
  const [long, setLong] = useStateIfMounted("");
  const [ubicacion, setUbicacion] = useStateIfMounted("");
  const [userPrincipal, setUserPrincipal] = useStateIfMounted(false);
  const [posToggle, setPosToggle] = useStateIfMounted(false);
  const [drawerToggle, setDrawerToggle] = useStateIfMounted(false);

  const [menuToggle, setMenuToggle] = useStateIfMounted(false);
  const [notificacionesToggle, setNotificacionesToggle] =
    useStateIfMounted(false);

  const [userPrincipalSubCuentas, setUserPrincipalSubCuentas] = useStateIfMounted();

  const _getPacienteData = async (uid) => {
    const { data } = await PacientesAPI.GetPacienteByUID(uid);
    try {
      setUser(data.data);
      setUserPrincipal(data.data);
      //props.history("/");
    } catch (error) {
      // console.log(error);
    }
  };

  const _getEspecialistaData = async (uid) => {
    const { data } = await EspecialistasAPI.GetEspecialistaByUID(uid);
    try {
      setUser(data.doctor);
      setUserPrincipal(data.doctor);
      //props.history("/");
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      <UserContext.Provider
        value={{
          setUser,
          user,
          subCuentas,
          setSubCuentas,
          tokens,
          setTokens,
          lat,
          setLat,
          long,
          setLong,
          ubicacion,
          setUbicacion,
          userPrincipal,
          setUserPrincipal,
          userPrincipalSubCuentas,
          setUserPrincipalSubCuentas,
          posToggle,
          setPosToggle,
          drawerToggle,
          setDrawerToggle,
          notificacionesToggle,
          setNotificacionesToggle,
          _getPacienteData,
          _getEspecialistaData,
          organizacion,
          setOrganizacion,
          menuToggle,
          setMenuToggle,
        }}
      >
        {children}
      </UserContext.Provider>
    </>
  );
};

export default UserContext;
