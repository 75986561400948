import React from "react";
import {
  Form,
} from "rsuite";
import colors from "../../constants/colors";

const ControlLabelCustom = (props) => {
  return <>
    <Form.ControlLabel style={{ ...props.style, marginTop: 4, marginBottom: 0, fontSize: 12, color: props.color ? props.color : colors.textSecondary, fontWeight: 'bold' }}>
      {props.children}
    </Form.ControlLabel>
  </>;
};

export default ControlLabelCustom;
