import React, { useEffect, useState } from "react";
import color from "../../constants/colors";

const ButtonLink = (props) => {
  return (
    <div
      className="button-accent-docca"
      onClick={() => (props.onClickFunction ? props.onClickFunction() : null)}
      style={{
        color: props.color ? props.color : color.accent,
        fontSize: 12,
        fontWeight: props.bold ? "bold" : 600,
        marginRight: props.mRight == null ? 0 : props.mRight,
        marginLeft: props.mLeft == null ? 0 : props.mLeft,
        marginBottom: props.mBottom == null ? 0 : props.mBottom,
        marginTop: props.mTop == null ? 0 : props.mTop,
        cursor: "pointer",
      }}
    >
      <span>{props.text}</span>
    </div>
  );
};

export default ButtonLink;
