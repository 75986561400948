import axios from "axios";
import api from "../constants/api";
const API_KEY = "AIzaSyBD8gCsno5Bv0YE8FonqZc6Lwp-pNDg12E";
const userID = 13;

export default class PacientesAPI {
  async GetUser() {
    return axios.get(`${api}/paciente/data/${userID}`, {});
  }

  async SearchEspecialista(search) {
    return axios.get(`${api}/paciente/buscar/especialista/${search}`);
  }

  async GetPaciente(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_paciente",
        app: "app_paciente",
        id_paciente,
      },
    });
  }

  async UpdateInfoContactoEmergencia(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_contacto_emergencia",
        app: "app_paciente",
        request,
      },
    });
  }

  async ChangePassword(request) {
    return axios.post(api, {
      params: {
        rt: "change_password_paciente",
        app: "auth",
        request,
      },
    });
  }

  async UpdateInfoFacturacion(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_facturacion",
        app: "app_paciente",
        request,
      },
    });
  }

  async UpdateDireccion(request) {
    return axios.post(api, {
      params: {
        rt: "update_direccion",
        app: "app_paciente",
        request,
      },
    });
  }

  async RegistroS1(request) {
    return axios.post(api, {
      params: {
        rt: "registro_step1",
        app: "app_paciente",
        request,
      },
    });
  }

  async UpdateInfoBasica(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_basica",
        app: "app_paciente",
        request,
      },
    });
  }
  async GetCatalogoProcedimientos(term) {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_procedimientos_autocomplete",
        app: "common",
        term,
      },
    });
  }
  async SaveEpisodioProcedimiento(request) {
    return axios.post(api, {
      params: {
        rt: "save_episodio_procedimiento",
        app: "app_paciente",
        request,
      },
    });
  }
  async SavePacienteAntecedenteNoPatologico(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_antecedente_no_patologico",
        app: "app_paciente",
        request,
      },
    });
  }

  async UpdateEpisodioProcedimiento(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_procedimiento",
        app: "app_paciente",
        request,
      },
    });
  }

  async GetCatalogoProcedimientosServicios() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_procedimientos_servicios",
        app: "common",
      },
    });
  }

  async DeleteProcedimientoManual(request) {
    return axios.post(api, {
      params: {
        rt: "delete_procedimiento_manual",
        app: "app_paciente",
        request,
      },
    });
  }

  async UploadFotoGaleria(request) {
    return axios.post(api, {
      params: {
        rt: "upload_foto_galeria",
        app: "app_paciente",
        request,
      },
    });
  }

  async UpdateInfoContacto(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_contacto",
        app: "app_paciente",
        request,
      },
    });
  }

  async UpdateAseguradora(request) {
    return axios.post(api, {
      params: {
        rt: "update_aseguradora",
        app: "app_paciente",
        request,
      },
    });
  }

  async DeleteAseguradora(request) {
    return axios.post(api, {
      params: {
        rt: "delete_aseguradora",
        app: "app_paciente",
        request,
      },
    });
  }

  async SaveAseguradora(request) {
    return axios.post(api, {
      params: {
        rt: "save_aseguradora",
        app: "app_paciente",
        request,
      },
    });
  }

  async updateFotoGaleria(request) {
    return axios.post(api, {
      params: {
        rt: "update_foto_galeria",
        app: "app_paciente",
        request,
      },
    });
  }

  async DeleteFotoGaleria(request) {
    return axios.post(api, {
      params: {
        rt: "delete_foto_galeria",
        app: "app_paciente",
        request,
      },
    });
  }

  async UpdatePacienteSinAlergias(request) {
    return axios.post(api, {
      params: {
        rt: "paciente_sin_alergias",
        app: "app_paciente",
        request,
      },
    });
  }

  async CreateVaccineBook(id_paciente) {
    return axios.post(api, {
      params: {
        rt: "create_pattient_vaccine_book",
        app: "app_paciente",
        id_paciente,
      },
    });
  }

  async RegeneratePatientVaccineBook(id_paciente) {
    return axios.post(api, {
      params: {
        rt: "regenerate_patient_vaccine_book",
        app: "app_paciente",
        id_paciente,
      },
    });
  }

  async UpdatePacienteSinPato(request) {
    return axios.post(api, {
      params: {
        rt: "paciente_sin_patologicos",
        app: "app_paciente",
        request,
      },
    });
  }
  async UpdatePacienteSinNoPato(request) {
    return axios.post(api, {
      params: {
        rt: "paciente_sin_no_patologicos",
        app: "app_paciente",
        request,
      },
    });
  }

  async UpdatePacienteSinHeredo(request) {
    return axios.post(api, {
      params: {
        rt: "paciente_sin_heredo",
        app: "app_paciente",
        request,
      },
    });
  }

  async UpdatePacienteSinDisca(request) {
    return axios.post(api, {
      params: {
        rt: "paciente_sin_discapacidades",
        app: "app_paciente",
        request,
      },
    });
  }

  async DeleteAlergiaById(id_alergia, id_paciente) {
    return axios.post(api, {
      params: {
        rt: "delete_alergia_by_id",
        app: "app_paciente",
        id_alergia,
        id_paciente,
      },
    });
  }

  async DeleteDiscapacidadById(id_disca, id_paciente) {
    return axios.post(api, {
      params: {
        rt: "delete_discapacidad_by_id",
        app: "app_paciente",
        id_disca,
        id_paciente,
      },
    });
  }

  async DeletePacienteTomaById(id_paciente) {
    return axios.post(api, {
      params: {
        rt: "set_paciente_no_toma",
        app: "app_paciente",
        id_paciente,
      },
    });
  }

  async DeletePacienteFumaById(id_paciente) {
    return axios.post(api, {
      params: {
        rt: "set_paciente_no_fuma",
        app: "app_paciente",
        id_paciente,
      },
    });
  }

  async DeletePacienteDrogaById(id_paciente) {
    return axios.post(api, {
      params: {
        rt: "set_paciente_no_droga",
        app: "app_paciente",
        id_paciente,
      },
    });
  }

  async DeleteHeredoById(id_heredo, id_paciente) {
    return axios.post(api, {
      params: {
        rt: "delete_heredo_by_id",
        app: "app_paciente",
        id_heredo,
        id_paciente,
      },
    });
  }

  async DeletePatoById(id_pato, id_paciente) {
    return axios.post(api, {
      params: {
        rt: "delete_pato_by_id",
        app: "app_paciente",
        id_pato,
        id_paciente,
      },
    });
  }

  async DeltePacienteAlergiasSinAlergias(request) {
    return axios.post(api, {
      params: {
        rt: "delete_alergias_set_sin_alergias",
        app: "app_paciente",
        request,
      },
    });
  }

  async DeltePacienteHeredoSinHeredo(request) {
    return axios.post(api, {
      params: {
        rt: "delete_heredo_set_sin_heredo",
        app: "app_paciente",
        request,
      },
    });
  }

  async DeltePacientePatoSinPato(request) {
    return axios.post(api, {
      params: {
        rt: "delete_pato_set_sin_pato",
        app: "app_paciente",
        request,
      },
    });
  }
  async DeltePacientePatoSinNoPato(request) {
    return axios.post(api, {
      params: {
        rt: "delete_nopato_set_sin_nopato",
        app: "app_paciente",
        request,
      },
    });
  }
  async DeltePacienteDiscaSinDisca(request) {
    return axios.post(api, {
      params: {
        rt: "delete_disca_set_sin_disca",
        app: "app_paciente",
        request,
      },
    });
  }

  async GetCatalogoLenguaIndigena() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_lenguas_indigenas",
        app: "common",
      },
    });
  }

  async GetPlanesAlimenticiosPaciente(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_planes_alimenticios_paciente",
        app: "app_paciente",
        id_paciente,
      },
    });
  }

  async GetGaleriaByDoctor(id_paciente, id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_galeria_paciente_doctor",
        app: "app_paciente",
        id_paciente,
        id_doctor,
      },
    });
  }


  async GetGaleria(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_galeria_paciente",
        app: "app_paciente",
        id_paciente,
      },
    });
  }

  async UpdatePaciente(request) {
    return axios.post(api, {
      params: {
        rt: "update_paciente",
        app: "app_paciente",
        request,
      },
    });
  }

  async SaveTratamiento(request) {
    return axios.post(api, {
      params: {
        rt: "save_tratamiento",
        app: "app_paciente",
        request,
      },
    });
  }

  async GetCatalogoGeneros() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_generos",
        app: "common",
      },
    });
  }

  async GetCatalogoNacionalidades() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_nacionalidades",
        app: "common",
      },
    });
  }

  async GetCatalogoReligion() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_religiones",
        app: "common",
      },
    });
  }

  async GetCatalogoEntidades() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_entidades",
        app: "common",
      },
    });
  }

  async GetCatalogoEstadoCivil() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_estado_civil",
        app: "common",
      },
    });
  }

  async GetPacienteByUID(uid) {
    return axios.get(api, {
      params: {
        rt: "get_user",
        app: "app_paciente",
        uid,
      },
    });
  }
  async GetEspecialidades() {
    return axios.get(`${api}/catalogo/especialidades`);
  }

  async GetDoctores(id) {
    return axios.get(api, {
      params: {
        rt: "get_doctores",
        app: "app_paciente",
        id,
      },
    });
  }  
  async GetCitaData(id_cita) {
    return axios.get(api, {
      params: {
        rt: "get_cita_data",
        app: "app_paciente",
        id_cita,
      },
    });
  }
 
  async GetDoctoresByFiltros(id, cont_filtros) {
    return axios.get(api, {
      params: {
        rt: "get_doctores_by_filtros",
        app: "app_paciente",
        id,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }
  async GetPagosPaciente(id_paciente, pagina, limite) {
    return axios.get(api, {
      params: {
        rt: "get_pagos_paciente_especialista",
        app: "app_paciente",
        id_paciente,
        pagina,
        limite,
      },
    });
  }
  async GetVenta(id_paciente, id_venta) {
    return axios.get(api, {
      params: {
        rt: "get_venta",
        app: "app_paciente",
        id_paciente,
        id_venta,
      },
    });
  }

  async GetServicios(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_servicios_doctor",
        app: "app_paciente",
        id_doctor,
      },
    });
  }

  async GetEpisodiosTabla(id_paciente, pagina, limite, search) {
    return axios.get(api, {
      params: {
        rt: "get_episodios_tabla",
        app: "app_paciente",
        id_paciente,
        pagina,
        limite,
        search,
      },
    });
  }

  async GetCitasMesAnio(
    id_paciente, id_organizacion, dia,
    mes, anio, pagina, limite,
    search, cont_filtros
  ) {
    return axios.get(api, {
      params: {
        rt: "get_citas_mes_anio",
        app: "app_paciente",
        id_paciente,
        id_organizacion,
        dia,
        mes,
        anio,
        pagina,
        limite,
        search,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }

  async GetSolicitudesPendientes(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_solicitudes_pendientes",
        app: "app_paciente",
        id_paciente,
      },
    });
  }
  async GetSolicitudesRechazadas(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_solicitudes_rechazadas",
        app: "app_paciente",
        id_paciente,
      },
    });
  }

  async login(request) {
    return axios.post(api, {
      params: {
        rt: "login_paciente",
        app: "auth",
        request,
      },
    });
  }

  async updateBienvenidaTerminada(request) {
    return axios.post(api, {
      params: {
        rt: "update_bienvenida_terminada",
        app: "app_paciente",
        request,
      },
    });
  } 
  async updateConfiguracionPerfilTerminada(request) {
    return axios.post(api, {
      params: {
        rt: "update_configuracion_perfil_terminada",
        app: "app_paciente",
        request,
      },
    });
  }
  async UpdateAvatarPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "update_avatar_paciente",
        app: "app_paciente",
        request,
      },
    });
  }
  async UpdateAlergia(request) {
    return axios.post(api, {
      params: {
        rt: "update_alergia",
        app: "app_paciente",
        request,
      },
    });
  }

  async StartConversacion(request) {
    return axios.post(api, {
      params: {
        rt: "start_conversacion_chat",
        app: "app_paciente",
        request,
      },
    });
  }

  async SaveAntecedentePatologico(request) {
    return axios.post(api, {
      params: {
        rt: "save_antecedente_patologico",
        app: "app_paciente",
        request,
      },
    });
  }

  async SaveDiscapacidad(request) {
    return axios.post(api, {
      params: {
        rt: "save_discapacidad",
        app: "app_paciente",
        request,
      },
    });
  }

  async UpdateDiscapacidad(request) {
    return axios.post(api, {
      params: {
        rt: "update_discapacidad",
        app: "app_paciente",
        request,
      },
    });
  }

  async DeleteDiscapacidad(request) {
    return axios.post(api, {
      params: {
        rt: "delete_discapacidad",
        app: "app_paciente",
        request,
      },
    });
  }

  async SaveHeredofamiliar(request) {
    return axios.post(api, {
      params: {
        rt: "save_heredofamiliar",
        app: "app_paciente",
        request,
      },
    });
  }

  async UpdateHeredofamiliar(request) {
    return axios.post(api, {
      params: {
        rt: "update_heredofamiliar",
        app: "app_paciente",
        request,
      },
    });
  }

  async DeleteAntecedentePatologico(request) {
    return axios.post(api, {
      params: {
        rt: "delete_antecedente_patologico",
        app: "app_paciente",
        request,
      },
    });
  }

  async DeleteNoPatologico(request) {
    return axios.post(api, {
      params: {
        rt: "delete_no_patologico",
        app: "app_paciente",
        request,
      },
    });
  }
  async GetDiscapacidadesCatalagoData() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_discapacidades",
        app: "common",
      },
    });
  }

  async SaveNoPatologico(request) {
    return axios.post(api, {
      params: {
        rt: "save_antecedente_no_patologico",
        app: "app_paciente",
        request,
      },
    });
  }

  async UpdateNoPatologico(request) {
    return axios.post(api, {
      params: {
        rt: "update_no_patologico",
        app: "app_paciente",
        request,
      },
    });
  }

  async SaveAntecedenteNoPatologicoFuma(request) {
    return axios.post(api, {
      params: {
        rt: "save_antecedente_no_patologico_fuma",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteHeredofamiliar(request) {
    return axios.post(api, {
      params: {
        rt: "delete_heredofamiliar",
        app: "app_paciente",
        request,
      },
    });
  }

  async UpdateAntecedentePatologico(request) {
    return axios.post(api, {
      params: {
        rt: "update_antecedente_patologico",
        app: "app_paciente",
        request,
      },
    });
  }

  async DeleteAlergia(request) {
    return axios.post(api, {
      params: {
        rt: "delete_alergia",
        app: "app_paciente",
        request,
      },
    });
  }

  async SaveAlergia(request) {
    return axios.post(api, {
      params: {
        rt: "save_alergia",
        app: "app_paciente",
        request,
      },
    });
  }
  async SetConsentimientoHistoriaClinica(request) {
    return axios.post(api, {
      params: {
        rt: "set_consentimiento_historia_clinica",
        app: "app_paciente",
        request,
      },
    });
  }
  async SavePacienteAlergia(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_alergia",
        app: "app_doctor",
        request,
      },
    });
  }
  async ReagendarCalendarioCita(
    id_reagenda,
    id_doctor,
    id_organizacion,
    id_paciente,
    id_consultorio,
    servicios,
    fecha,
    id_inicio,
    id_fin,
    personal,
    descripcion,
    id_creador,
    tipo_creador,
    status,
    tipo
  ) {
    return axios.post(api, {
      params: {
        rt: "reagendar_calendario_cita",
        app: "app_paciente",
        id_reagenda,
        id_doctor,
        id_organizacion,
        id_paciente,
        id_consultorio,
        servicios,
        fecha,
        id_inicio,
        id_fin,
        personal,
        descripcion,
        id_creador,
        tipo_creador,
        status,
        tipo
      },
    });
  }



  async SaveCalendarioCita(
    id_doctor,
    id_organizacion,
    id_paciente,
    id_consultorio,
    servicios,
    fecha,
    id_inicio,
    id_fin,
    personal,
    descripcion,
    status,
    id_creador,
    tipo_creador
  ) {
    return axios.post(api, {
      params: {
        rt: "save_calendario_cita",
        app: "app_paciente",
        id_doctor,
        id_organizacion,
        id_consultorio,
        id_paciente,
        servicios,
        fecha,
        id_inicio,
        id_fin,
        personal,
        descripcion,
        id_creador,
        tipo_creador,
        status,
      },
    });
  }

  async GetConsultorios(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_consultorios_doctor",
        app: "app_paciente",
        id_doctor,
      },
    });
  }
  async GetCatalogoMedicamentoViasDeAdministracion() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_vias_de_administracion",
        app: "common",
      },
    });
  }

  async ChangePassword(request) {
    return axios.post(api, {
      params: {
        rt: "change_password_paciente",
        app: "auth",
        request,
      },
    });
  }

  async GetTratameintos(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_tratamientos_paciente",
        app: "app_paciente",
        id_paciente,
      },
    });
  }

  async getTratamiento(id_paciente, id) {
    return axios.get(api, {
      params: {
        rt: "get_tratamiento",
        app: "app_paciente",
        id_paciente,
        id,
      },
    });
  }

  async ComenzarTratamiento(request) {
    return axios.post(api, {
      params: {
        rt: "comenzar_tratamiento",
        app: "app_paciente",
        request,
      },
    });
  }

  async GetDisponibilidadDoctor7DiasAgenda(
    id_doctor,
    fecha,
    tiempo_servicio,
    id_consultorio
  ) {
    return axios.get(api, {
      params: {
        rt: "get_disponibilidad_doctor_7_dias_agenda",
        app: "app_paciente",
        id_doctor,
        fecha,
        tiempo_servicio,
        id_consultorio,
      },
    });
  }

  async GetHorariosDisponiblesDia(id_doctor, tiempo, fecha) {
    return axios.get(api, {
      params: {
        rt: "get_horarios_disponibles_dia",
        app: "app_paciente",
        id_doctor,
        tiempo,
        fecha,
      },
    });
  }

  async GetCalendarioDisponibilidad(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_calendario_disponibilidad",
        app: "app_paciente",
        id_doctor,
      },
    });
  }

  async GetDoctor(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_doctor",
        app: "app_paciente",
        id_doctor,
      },
    });
  }

  async GetCatalogoEspecialidades() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_especialidades",
        app: "common",
      },
    });
  }

  async GetCita(id_paciente, id_cita) {
    return axios.get(api, {
      params: {
        rt: "get_cita",
        app: "app_paciente",
        id_paciente,
        id_cita,
      },
    });
  }

  async GetEpisodios(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_episodios",
        app: "app_paciente",
        id_paciente,
      },
    });
  }

  async GetChat(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_chat",
        app: "app_paciente",
        id_paciente,
      },
    });
  }

  async GetEpisodio(id_paciente, id_episodio) {
    return axios.get(api, {
      params: {
        rt: "get_episodio",
        app: "app_paciente",
        id_paciente,
        id_episodio,
      },
    });
  }

  async GetSeccionesEpisodio() {
    return axios.get(api, {
      params: {
        rt: "get_secciones_episodio",
        app: "common"
      },
    });
  }


  async GetCatalogoTipoEpisodio() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_tipo_episodio",
        app: "common",
      },
    });
  }

  async GetEpisodiosByFiltros(id_paciente, cont_filtros) {
    return axios.get(api, {
      params: {
        rt: "get_episodios_by_filtros",
        app: "app_paciente",
        id_paciente,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }
  async GetSolicitudes(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_solicitudes",
        app: "app_paciente",
        id_paciente,
      },
    });
  }

  async GetReceta(id_receta) {
    return axios.get(api, {
      params: {
        rt: "get_receta",
        app: "app_paciente",
        id_receta,
      },
    });
  }


  async GetCitas(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_citas",
        app: "app_paciente",
        id_paciente,
      },
    });
  }

  async GetLocation(lat, long) {
    return axios.get(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      lat +
      "," +
      long +
      "&key=" +
      API_KEY
    );
  }
}
