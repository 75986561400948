import React from "react";
import color from "../../constants/colors";

const Error = (props) => {
  return (
    <div
      style={{
        height: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: color.errorLighter,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 6,
        marginTop: 5,
      }}
    >
      <span style={{ fontSize: "12px", color: color.error }}>{props.text}</span>
    </div>
  );
};

export default Error;
