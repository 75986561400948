import React from "react";
import Button from "rsuite/Button";

const ButtonAccent = (props) => {
  return (
    <Button
      block={props.block ? true : false}
      loading={props.loader ? true : false}
      size={props.size ? props.size : "lg"}
      style={{
        ...props.style,
        ...{
          marginLeft: props.marginLeft && props.marginLeft,
          marginRight: props.marginRight && props.marginRight,
        },
      }}
      disabled={props.disabled ? true : false}
      appearance="primary"
      color={props.color ? props.color : "blue"}
      onClick={() => (props.onClick ? props.onClick() : null)}
    >
      <span style={{ fontWeight: 600 }}>{props.text}</span>
    </Button>
  );
};

export default ButtonAccent;
